<template>
	<div class="mt-lg-4">
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<h3>{{ $t('pax.selection_etalon') }}</h3>
			<div class="row" id="selected_stallions">
				<div class="col-12 stallion" v-for="stallion in stallions_local" :key="stallion.horse_id">
					<div>
						{{ stallion.horse_nom }}
						<a href="#" class="ml-auto" @click.prevent="deleteStallion(stallion.horse_id)"><font-awesome-icon :icon="['fal', 'times']" /></a>
					</div>
				</div>
			</div>
			<a href="#" class="btn btn-dark btn-back" @click.prevent="editStallion">{{ $t('pax.modifier') }} <span class="icone"><font-awesome-icon :icon="['far', 'undo-alt']" /></span></a>
			<a href="#" class="btn btn-primary btn-next" @click.prevent="nextStep">{{ $t('pax.choisir_jument') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></a>
		</div>
	</div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import _cloneDeep from 'lodash/cloneDeep';

	export default {
		name: "Selection",
		props: {
			force_refresh: {
				type: String,
				default: () => ( "" )
			},
			stallions: {
				type: Array,
				default: () => ( [] )
			},
			step_label: {
				type: String,
				default: () => null
			}
		},
		mixins: [Navigation, PaxMixin, Tools],
		data () {
			return {
				addOrEditReady: false,
				
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.addOrEditReady = false

				this.stallions_local = _cloneDeep(this.stallions)

				this.addOrEditReady = true
			},

			async deleteStallion(horse_id) {
				let horse_to_remove = this.stallions_local.find(elem => elem.horse_id == horse_id)

				this.stallions_local.splice(this.stallions_local.indexOf(horse_to_remove), 1)

				this.$emit("update:stallions", this.stallions_local)
			},

			async editStallion() {
				this.$emit("update:step_label", "stallions")
			},

			async nextStep() {
				this.$emit("update:step_label", "mare_list")
			}
		},
		watch: {
			force_refresh() {
				this.init_component()
			},
			stallions(val) {
				this.init_component()
			},
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
